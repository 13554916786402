import request from '../utils/request';
import qs from "qs";

// eslint-disable-next-line no-unused-vars
export const notice = companyId => {
    return request.get('/api/notice/list')
};

export const add = param => {
    return request.post('/api/notice/addNoticeWithQuill', qs.stringify(param))
};


export const delNotice = id => {
	return request.get('/api/notice/deleteNotice?id=' + id)
};
export const getSlideshowList = userId => {
	return request.get('/api/slideshow/list?userId=' + userId)
};
export const SlideshowAdd = param => {
	return request.post('/api/slideshow/add', qs.stringify(param))
};
export const SlideshowDelete = id => {
	return request.post('/api/slideshow/delete?id=' + id)
};